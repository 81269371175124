export default function SparkleIcon(props: {
	size?: number | string;
	color?: string;
	class?: string;
}) {
	return (
		<svg
			class={props.class}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size}
			height={props.size}
			viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M8.665 15.735c.245.173.537.265.836.264v-.004a1.44 1.44 0 0 0 1.327-.872l.613-1.864a2.87 2.87 0 0 1 1.817-1.812l1.778-.578a1.442 1.442 0 0 0-.052-2.74l-1.755-.57a2.88 2.88 0 0 1-1.822-1.823l-.578-1.777a1.446 1.446 0 0 0-2.732.022l-.583 1.792a2.88 2.88 0 0 1-1.77 1.786l-1.777.57a1.444 1.444 0 0 0 .017 2.735l1.754.569a2.89 2.89 0 0 1 1.822 1.826l.578 1.775c.099.283.283.527.527.7m7.667 5.047a1.12 1.12 0 0 1-.41-.55l-.328-1.006a1.3 1.3 0 0 0-.821-.823l-.991-.323a1.15 1.15 0 0 1-.781-1.083a1.14 1.14 0 0 1 .771-1.08l1.006-.326a1.3 1.3 0 0 0 .8-.82l.324-.991a1.143 1.143 0 0 1 2.157-.021l.329 1.014a1.3 1.3 0 0 0 .82.816l.992.323a1.141 1.141 0 0 1 .039 2.165l-1.014.329a1.3 1.3 0 0 0-.818.822l-.322.989c-.078.23-.226.43-.425.57a1.14 1.14 0 0 1-1.328-.005"
			/>
		</svg>
	);
}
